import moment from "moment";
import { colors } from "../../../utils/helpers/estilosGerais";
import { monthOfTheYear } from "./constants";

//Id do evento
export const generateId = (type, date, repeat) => {
  const random = parseInt(Math.random() * 10000); //Identificador base: random + data
  const arrayDate = moment(date).format("DD/MM/YYYY").split("/");
  const stringDate = `${arrayDate[0]}${arrayDate[1]}${arrayDate[2]}`;
  const isSingleEvent = repeat ? 0 : 1; //Evento único ou recorrente? 1 = único; 0 = recorrente
  const id = `${type}-${stringDate}${random}-${isSingleEvent}-0`;
  return id;
};

export const equals = (a, b) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

const setOpacity = (hex) =>
  `${hex}${Math.floor(0.5 * 255)
    .toString(16)
    .padStart(2, 0)}`;

// export const selectColor = (details, tutorsArray, userId) => {
//   let selectedColor = null;
//   if (details.type === "event") {
//     selectedColor = details.color[userId]
//       ? details.color[userId]
//       : colors.eventDefault;
//   } else if (details.type === "class") {
//     const selectedTutor = tutorsArray.filter(
//       (tutor) => tutor.localId === details.tutor.localId
//     );
//     selectedColor =
//       selectedTutor.length > 0 ? selectedTutor[0].color : colors.classDefault;
//   } else {
//     selectedColor = colors.eventDefault;
//   }

//   const exibitionColor =
//     moment().isAfter(moment(details.end)) && !details.allDay
//       ? setOpacity(selectedColor)
//       : selectedColor;

//   return exibitionColor;
// };

export const selectColor = (details, tutorsArray, userId) => {
  let color = {
    backgroundColor: null,
    textColor: null,
    borderColor: null,
  };
  let selectedColor = null;

  if (details.type === "event") {
    selectedColor = details.color[userId]
      ? details.color[userId]
      : colors.eventDefault;
    const error = details.participants.some(participant => participant.answered && !participant.accepted)
    color.backgroundColor = error ? "#ffffff" : selectedColor;
    color.textColor = error ? selectedColor : "#ffffff";
    color.borderColor = selectedColor;
  } else if (details.type === "class") {
    const selectedTutor = tutorsArray.filter(
      (tutor) => tutor.localId === details.tutor.localId
    );
    selectedColor =
      selectedTutor.length > 0 ? selectedTutor[0].color : colors.classDefault;
    color.backgroundColor = details.tutor.answered
      ? details.tutor.accepted
        ? selectedColor
        : "#ffffff"
      : "#ffffff";
    color.textColor = details.tutor.answered
      ? details.tutor.accepted
        ? "#ffffff"
        : selectedColor
      : selectedColor;
    color.borderColor = selectedColor;
  } else {
    color.backgroundColor = colors.unavailability;
    color.textColor = "#ffffff";
    color.borderColor = colors.unavailability;
  }

  if (moment().isAfter(moment(details.end)) && !details.allDay) {
    color.backgroundColor = setOpacity(color.backgroundColor);
    color.borderColor = setOpacity(color.borderColor);
  }

  return color;
};

export const generateFrequencies = (date) => {
  const day = date.date();
  let weekDay = "";
  switch (date.day()) {
    case 0:
      weekDay = "domingo";
      break;
    case 1:
      weekDay = "segunda-feira";
      break;
    case 2:
      weekDay = "terça-feira";
      break;
    case 3:
      weekDay = "quarta-feira";
      break;
    case 4:
      weekDay = "quinta-feira";
      break;
    case 5:
      weekDay = "sexta-feira";
      break;
    case 6:
      weekDay = "sábado";
      break;
    default:
      weekDay = "segunda-feira";
      break;
  }
  const options = [
    { description: "Não se repete", index: 0 },
    { description: "Todos os dias", index: 1 },
    {
      description: `Semanal: Cada ${weekDay}`,
      index: 2,
    },
    { description: `Mensal: Todo dia ${day}`, index: 3 },
    { description: "Intervalo personalizado...", index: 4 },
  ];
  return options;
};

export const generateDaysOfWeek = (array) => {
  let days = "";
  const daysOfWeek = [
    "domingo",
    "segunda-feira",
    "terça-feira",
    "quarta-feira",
    "quinta-feira",
    "sexta-feira",
    "sábado",
  ];
  //Array possui os índices do dia da semana: 0 - Domingo, ..., 6 - Sábado
  //Abaixo faz a construção da mensagem: dia, dia, ... e dia. Se o usuário escolher mais de um dia.
  if (array.length > 1) {
    array.forEach((day, index) => {
      if (index === array.length - 1) {
        days = days + " e " + daysOfWeek[day];
      } else {
        if (index === 0) {
          days = days + daysOfWeek[day];
        } else {
          days = days + ", " + daysOfWeek[day];
        }
      }
    });
  } else {
    //Se o usuário tiver escolhido somente um dia, retorna o dia da semana correspondente ao índice.
    days = daysOfWeek[array[0]];
  }
  return days;
};

export const getStatus = (key) => {
  switch (key) {
    case "yes":
      return "Aula dada";
    case "no":
      return "Aluno faltou";
    case "suspended":
      return "Suspensa";
    case "holiday":
      return "Feriado";
    default:
      return "Aula dada";
  }
};

export const setTitleClass = (details) => {
  switch (details.confirmed) {
    case "suspended":
      return (
        "[SUSPENSA] Aluno " +
        details.student.name +
        " - " +
        "Tutor " +
        details.tutor.name
      );
    case "no":
      return (
        "[FALTOU] Aluno " +
        details.student.name +
        " - " +
        "Tutor " +
        details.tutor.name
      );
    case "yes":
      return "Aluno " + details.student.name + " - Tutor " + details.tutor.name;
    case "holiday":
      return (
        "[FERIADO] Aluno " +
        details.student.name +
        " - " +
        "Tutor " +
        details.tutor.name
      );
    default:
      break;
  }
};

export const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
};

export const getRepetitionType = (type) => {
  switch (type) {
    case "week":
      return "semanalmente";
    case "day":
      return "diariamente";
    case "month":
      return "mensalmente";
    case "year":
      return "anualmente";
    default:
      break;
  }
};

//Para gerar a mensagem para o dia do mês, ou dia do ano, caso a frequência escolhida tenha sido a mensal ou anual
export const generateMessage = (info, op) => {
  if (moment.isMoment(moment(info))) {
    const date = moment(info).toDate();
    const day = date.getDate();
    const month = monthOfTheYear[date.getMonth()];
    if (op === "mensalmente") {
      //Se mensalmente, retorna apenas o número dia
      return ` ${day}`;
    } else {
      //Se anualmente, dia e mês
      return ` ${day} de ${month}`;
    }
  } else {
    return "Data inválida";
  }
};

export function getBase64(file) {
  return new Promise(function (resolve, reject) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let aux = [];
      const array = reader.result.split(";");
      aux = array[0].split(":");
      let type = aux[1];
      aux = aux[1].split("/");
      let fileType = aux[1];
      if (fileType.includes("vnd")) {
        fileType = "docx";
        type = "application/msword";
      }
      aux = array[1].split(",");
      const base64 = aux[1];
      const data = { type, fileType, base64 };
      resolve(data);
    };
    reader.onerror = reject;
  });
}

//var file = document.querySelector('#files > input[type="file"]').files[0];
