import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk"
import userReducer from "./reducers/userReducer";
import reportReducer from "./reducers/reportReducer";
import calendarReducer from "./reducers/calendarReducer"
import registerReducer from "./reducers/registerReducer"



const reducers = combineReducers({
  user: userReducer,
  reports: reportReducer,
  calendar: calendarReducer,
  register: registerReducer
});

const storeConfig = () => {
  return createStore(reducers, compose(applyMiddleware(thunk)));
};

export default storeConfig;
