import React from 'react'
import {Switch, Route, Redirect} from 'react-router'
 
import PaginaInicial from '../modules/PaginaInicial/PaginaInicial'
//Páginas dos relatórios
import Relatorios from '../modules/Relatorios/Busca/Busca'
import NovoRelatorio from '../modules/Relatorios/NovoRelatorio/NovoRelatorio'
import LeituraRelatorio from '../modules/Relatorios/Leitura/Leitura'
import EdicaoRelatorio from '../modules/Relatorios/Edicao/Edicao'
//Configurações
import AlterarSenha from '../modules/Configuracao/AlterarSenha'
//Agenda
import Detalhes from '../modules/Agenda/Detalhes'
import Agenda from '../modules/Agenda/Principal'



export default props => (
  <Switch>
    <Route exact path="/" component={PaginaInicial} />
    <Route exact path="/relatorios" component={Relatorios} />
    <Route exact path="/relatorios/novo" component={NovoRelatorio} />
    <Route exact path="/relatorios/visualizar/:id" component={LeituraRelatorio} />
    <Route exact path="/relatorios/editar/:id" component={EdicaoRelatorio} />
    <Route exact path="/configuracoes/alterar-senha" component={AlterarSenha} />

    <Route exact path="/agenda/detalhes/:userId/:classId" component={Detalhes} />
    {/* <Route exact path="/agenda" component={Agenda} /> */}

    <Redirect from="*" to="/"/>
  </Switch>
)
