import React from "react";
import { Button, Modal, Tag, Checkbox } from "antd";
import Seletor from "./Seletor";

export default ({
  visible,
  onOk,
  onCancel,
  students,
  tutors,
  selected,
  handleChange,
  allSelected,
  myClasses,
  myEvents,
  unavailabilities,
  userInfos,
}) => {
  return (
    <Modal
      title="Agendas"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Ok"
      cancelText="Cancelar"
      centered
      maskClosable
    >
      <div className="modal-schedules-section">
        <div className="row mx-0 align-items-center mb-2">
          <Checkbox
            checked={myClasses}
            onClick={() => handleChange(null, "selectMyClasses")}
            className="upper-checkbox"
          >
            Aulas
          </Checkbox>
          <Checkbox
            checked={myEvents}
            onClick={() => handleChange(null, "selectMyEvents")}
            className="upper-checkbox"
          >
            Eventos
          </Checkbox>
          <Checkbox
            checked={unavailabilities}
            onClick={() => handleChange(null, "selectUnavailability")}
            className="upper-checkbox"
          >
            Indisponibilidades
          </Checkbox>
        </div>
      </div>
      {userInfos.type === "orientador" && (
          <>
            <Seletor
              title="Tutores"
              type="tutor"
              selected={selected}
              handleChange={handleChange}
              list={tutors}
              showSelectAll
              selectAll={allSelected}
            />
            <Seletor
              title="Alunos"
              type="student"
              selected={selected}
              handleChange={handleChange}
              list={students}
            />
            <div className="modal-schedules-section">
              <div className="row mx-0 align-items-center mb-2">
                <p className="modal-section-title mb-0">Agendas selecionadas</p>
                <Button
                  type="text"
                  className="schedules-clear"
                  onClick={() => handleChange(null, "clearSchedules")}
                >
                  Limpar
                </Button>
              </div>
              <div className="row mx-0">
                {selected.map((item) => {
                  return (
                    <Tag
                      closable
                      key={`tag-schedule-${item}`}
                      onClose={() => handleChange(item, "removeSchedule")}
                    >
                      {item}
                    </Tag>
                  );
                })}
              </div>
            </div>
          </>
        )}
    </Modal>
  );
};
