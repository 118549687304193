import {
  SORT_REPORTS,
  FILTER_REPORT_BY_NAME,
  CLEAR_FILTER,
  FILTER_REPORT_BY_DATE,
  UPLOADING_REPORT,
  REPORT_SENT,
  SET_REPORTS,
  SET_OLD_REPORTS,
  REPORT_LOGGED_OUT
} from "./actionTypes";
import axios from "axios";
import { message } from "antd";
import moment from "moment";
import _ from "lodash";

const functionsBaseURL =
  "https://us-central1-banco-de-dados-realize.cloudfunctions.net";

export const fetchReports = (lastDate) => {
  //lastDate é um timestamp
  let olderTimestamp = null;
  let newerTimestamp = null;

  //Se a requisição não tiver vindo da inicialização do App, lastDate vai ser a data do último relatório armazenado na Store
  //Neste caso, as requisições de relatórios ao banco de dados vão sendo incrementadas por semana, ou seja,
  //faz-se sempre a requisição dos relatórios da semana anterior.
  if (lastDate > 0) {
    newerTimestamp = lastDate; //*Dias*horas*min*seg*miliseg
    olderTimestamp = lastDate - 7 * 24 * 60 * 60 * 1000;
  } else {
    //Neste caso é apenas a requisição dos relatórios da última semana.
    const newDate = new Date();
    const oldMoment = moment(newDate).subtract(7, "d");
    const oldDate = new Date(oldMoment);
    olderTimestamp = oldDate.getTime();
    newerTimestamp = newDate.getTime();
    //olderTimestamp = 1609815600000;
    //newerTimestamp = 1610420400000;
  }

  return (dispatch, getState) => {
    dispatch(uploadingReport());
    const token = getState().user.userValidation.token;
    axios
      .get(
        `/relatorios.json?auth=${token}&orderBy="timestamp"&startAt=${olderTimestamp}&endAt=${newerTimestamp}`
      )
      .catch((err) =>
        message.error(
          "RP01. Ocorreu um erro ao recuperar os relatórios. Por favor, tente novamente ou refaça o login."
        )
      )
      //Caso o get tenha sido bem sucedido, preenche um array com os resultado e o repassa ao reducer.
      .then((res) => {
        const storedReports = getState().reports.reports;
        const previousTimestamps = getState().reports.timestamps;
        const rawReports = res.data;
        let pos = -1;
        const reports = [];
        for (let key in rawReports) {
          if (getState().user.type === "orientador") {
            pos = findPosition(storedReports, rawReports[key].id);
            if (rawReports[key].id && pos < 0) {
              if (
                rawReports[key].verified === true ||
                rawReports[key].tutor === getState().user.name
              ) {
                reports.push({
                  ...rawReports[key],
                });
              } 
            } else {
              if (!_.isEqual(rawReports[key], storedReports[pos])) {
                if (
                  rawReports[key].verified === true ||
                  rawReports[key].tutor === getState().user.name
                ) {
                  storedReports[pos] = {
                    ...rawReports[key],
                  };
                } 
              }
            }
          } else {
            pos = findPosition(storedReports, rawReports[key].id);
            if (rawReports[key].id && pos < 0) {
              if (rawReports[key].tutor === getState().user.name) {
                reports.push({
                  ...rawReports[key],
                });
              }
            } else {
              if (!_.isEqual(rawReports[key], storedReports[pos])) {
                if (rawReports[key].tutor === getState().user.name) {
                  storedReports[pos] = {
                    ...rawReports[key],
                  };
                }
              }
            }
          }
        }
        let allReports = [];
        //if (lastDate > 0) {
        allReports = storedReports.concat(reports);
        //} else {
        //allReports = [...reports];
        //}

        const sorted = sortByDateAndVerification(allReports);

        localStorage.setItem("reports", JSON.stringify(sorted));

        if (lastDate > 0) {
          localStorage.setItem(
            "timestamps",
            JSON.stringify({
              older: olderTimestamp,
              newer: previousTimestamps.newer,
            })
          );
          dispatch(
            setReports(sorted, olderTimestamp, previousTimestamps.newer)
          );
        } else {
          localStorage.setItem(
            "timestamps",
            JSON.stringify({ older: olderTimestamp, newer: newerTimestamp })
          );
          dispatch(setReports(sorted, olderTimestamp, newerTimestamp));
        }
      });
  };
};

export const fetchReportsByStudent = (name) => {
  return (dispatch, getState) => {
    dispatch(uploadingReport());
    const token = getState().user.userValidation.token;
    const type = getState().user.type;
    axios
      .get(`/relatorios.json?auth=${token}&orderBy="student"&equalTo="${name}"`)
      .then((res) => {
        const oldReports = getState().reports.oldReports;
        const rawReports = res.data;
        let reports = [];
        let allReports = [];

        for (let key in rawReports) {
          if (
            rawReports[key].id &&
            findPosition(oldReports, rawReports[key].id) < 0
          ) {
            if (type === "orientador") {
              if (
                rawReports[key].verified === true ||
                rawReports[key].tutor === getState().user.name
              ) {
                reports.push({
                  ...rawReports[key],
                });
              }
            } else {
              if (rawReports[key].tutor === getState().user.name) {
                reports.push({
                  ...rawReports[key],
                });
              }
            }
          }
        }

        allReports = oldReports.concat(reports);
        const sorted = sortByDateAndVerification(allReports);
        dispatch(setOldReports(sorted));
      })
      .catch((err) => {
        console.log(err);
        message.error("Ocorreu um erro na busca pelo aluno.");
      });
  };
};

export const newReport = (report) => {
  return (dispatch, getState) => {
    dispatch(uploadingReport());
    const token = getState().user.userValidation.token;
    axios
      .patch(`/relatorios/${report.id}.json?auth=${token}`, { ...report })
      .catch((err) =>
        message.error(
          "RP04. Ocorreu um erro ao enviar o relatório. Por favor, tente novamente ou refaça o login."
        )
      )
      .then(() => {
        let storedReports = getState().reports.reports;
        const previousTimestamps = getState().reports.timestamps;
        storedReports.push({
          ...report,
        });
        const sorted = sortByDateAndVerification(storedReports);
        dispatch(
          setReports(sorted, previousTimestamps.older, previousTimestamps.newer)
        );
        localStorage.removeItem("reports");
        localStorage.setItem("reports", JSON.stringify(sorted.reverse()));
      });
  };
};

export const editReport = (report) => {
  return (dispatch, getState) => {
    dispatch(uploadingReport());
    const token = getState().user.userValidation.token;
    axios
      .patch(`/relatorios/${report.id}.json?auth=${token}`, { ...report })
      .catch((err) =>
        message.error(
          "RP04. Erro ao acessar os relatórios. Por favor, tente novamente ou refaça o login."
        )
      )
      .then(() => {
        let storedReports = getState().reports.reports;
        let storedOldReports = getState().reports.oldReports;
        const previousTimestamps = getState().reports.timestamps;
        let index = findPosition(storedReports, report.id);
        let sorted = [];
        //Se o relatório estiver entre aqueles dentro do filtro de tempo
        if (index >= 0) {
          storedReports[index] = { ...report };
          sorted = sortByDateAndVerification(storedReports);
          dispatch(
            setReports(
              sorted,
              previousTimestamps.older,
              previousTimestamps.newer
            )
          );
          localStorage.removeItem("reports");
          localStorage.setItem("reports", JSON.stringify(sorted.reverse()));
          //Se o relatório não estiver entre aqueles dentro do filtro de tempo...
        } else {
          if (
            report.timestamp >= previousTimestamps.older &&
            report.timestamp <= previousTimestamps.newer
          ) {
            storedReports.push({ ...report });
            sorted = sortByDateAndVerification(storedReports);
            dispatch(
              setReports(
                sorted,
                previousTimestamps.older,
                previousTimestamps.newer
              )
            );
            localStorage.removeItem("reports");
            localStorage.setItem("reports", JSON.stringify(sorted));
            message.success("Relatório atualizado no banco de dados.");
          } else {
            index = findPosition(storedOldReports, report.id);
            if (index >= 0) {
              storedOldReports[index] = { ...report };
              sorted = sortByDateAndVerification(storedOldReports);
              dispatch(setOldReports(sorted));
            }
          }
        }
      });
  };
};

export const setOldReports = (reports) => {
  return {
    type: SET_OLD_REPORTS,
    payload: reports,
  };
};

export const filterByDate = (initialDate, finalDate) => {
  return {
    type: FILTER_REPORT_BY_DATE,
    payload: {
      initialDate: initialDate,
      finalDate: finalDate,
    },
  };
};

export const clearFilter = () => {
  return {
    type: CLEAR_FILTER,
  };
};

const findPosition = (reports, id) => {
  let position = -1;
  for (let i = 0; i < reports.length; i++) {
    if (reports[i].id === id) {
      position = i;
    }
  }
  return position;
};

export const setReports = (reports, older, newer) => {
  return {
    type: SET_REPORTS,
    payload: { reports, older, newer },
  };
};

export const uploadingReport = () => {
  return {
    type: UPLOADING_REPORT,
  };
};

export const reportUploaded = () => {
  return {
    type: REPORT_SENT,
  };
};

export const sortReports = () => {
  return {
    type: SORT_REPORTS,
  };
};

export const filterByName = (filter) => {
  return {
    type: FILTER_REPORT_BY_NAME,
    payload: filter,
  };
};

const sortByDateAndVerification = (allReports) => {
  //Ordenação entre relatórios verificados e não verificados
  let verified = [];
  let unverified = [];
  allReports.forEach((item) => {
    if (item.verified) {
      verified.push({ ...item });
    } else {
      unverified.push({ ...item });
    }
  });

  //Ordenação por timestamp
  let ver_sorted = [];
  let unv_sorted = [];
  ver_sorted = verified.sort((a, b) => b.timestamp - a.timestamp);
  unv_sorted = unverified.sort((a, b) => b.timestamp - a.timestamp);

  return unv_sorted.concat(ver_sorted);
};

export const report_logout = () => {
  return {
    type: REPORT_LOGGED_OUT,
  };
};