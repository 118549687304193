import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../utils/template/Header/Header";
import Logo from "../../utils/template/Logo/Logo";
import Menu from "../../utils/template/Menu/Menu";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import moment from "moment";
import { Modal, Form, Radio, Tag } from "antd";
import ItemFormulario from "./ItemFormulario";
import { getStatus, selectColor } from "./helpers/functions";
import SelecaoAgendas from "./SelecaoAgendas/SelecaoAgendas";
import SelecaoFrequencias from "./SelecaoFrequencias";
import { SliderPicker } from "react-color";
import { colors } from "../../utils/helpers/estilosGerais";
import {
  fetchAllClasses,
  fetchUserClasses,
  fetchUserEvents,
  deleteEvents,
  uploadNewEvents,
  updateClass,
  changeTutorInClass,
  updateEvents,
  changeOfPace,
  answerInvite,
} from "../../utils/store/actions/calendarActions";
import { customFrequency } from "./helpers/constants";
import {
  generateMessage,
  generateDaysOfWeek,
  generateId,
  generateFrequencies,
  setTitleClass,
  equals,
} from "./helpers/functions";
import ModalExclusao from "./ModalExclusao";
import ModalResposta from "./ModalResposta";
import ModalInfo from "./ModalInfo";
import Edicao from "./Edicao";
import _ from "lodash";

const formItemLayout = {
  wrapperCol: { span: 20 },
};

class Principal extends Component {
  state = {
    selectedEvent: {},
    selectedUsers: [],
    visible: {
      entries: false,
      checkBox: false,
      frequency: false,
      colorPicker: false,
      exclusion: false,
      infos: false,
      edition: false,
      answer: false,
    },
    isColorPickerVisible: false,
    allTutorsSelected: false,
    exclusionType: "this",
    answerType: "this",
    answer: true,
    sendNotification: "no",
    loadingFiles: false,
    myClasses: true,
    myEvents: true,
    unavailabilities: false,
    color: colors.classDefault,
    selectInfo: null,
    clickInfo: null,
    selectedDate: moment(),
    selectedTime: ["00:00", "00:00"],
    type: "event",
    student: { name: "Selecione o aluno" },
    tutor: { name: "Selecione o tutor" },
    participants: [],
    attachments: [],
    frequency: { description: "Não se repete", index: 0 },
    title: "",
    description: "",
    moment: null,
    allDay: false,
    error: {
      type: false,
      title: false,
      time: false,
      date: false,
      description: false,
      student: false,
      tutor: false,
      frequency: false,
    },
  };

  //Seta o relatório do estado da tela como o selecionado pelo usuário na página de Buscas
  componentDidMount() {
    this.setState({
      ...this.state,
      selectedUsers: ["Aulas", "Eventos"],
      myClasses: true,
      myEvents: true,
      holidays: true,
    });
  }

  handleOk = () => {
    //Verifica se todos os campos obrigatórios foram preenchidos
    let error = { ...this.state.error };
    if (this.state.type === "class") {
      error.student =
        this.state.student.name.trim() === "" ||
        this.state.student.name === "Selecione o aluno";
      error.tutor =
        this.state.tutor.name.trim() === "" ||
        this.state.tutor.name === "Selecione o tutor";
      error.date = this.state.selectedDate === null;
      error.time =
        moment(this.state.selectedTime[1], "HH:mm").isSameOrBefore(
          moment(this.state.selectedTime[0], "HH:mm")
        ) && !this.state.allDay;
    } else {
      error.date = this.state.selectedDate === null;
      error.time =
        moment(this.state.selectedTime[1], "HH:mm").isSameOrBefore(
          moment(this.state.selectedTime[0], "HH:mm")
        ) && !this.state.allDay;
    }

    const exists = Object.keys(error).some(function (k) {
      return error[k];
    });

    //Se tudo estiver preenchido, prossegue com a criação do evento
    if (!exists) {
      const day = moment(this.state.selectedDate).format("DD-MM-YYYY");
      const start = moment(
        `${day} ${this.state.selectedTime[0]}`,
        "DD-MM-YYYY HH:mm"
      );
      const end = moment(
        `${day} ${this.state.selectedTime[1]}`,
        "DD-MM-YYYY HH:mm"
      );

      let calendarApi = this.state.selectInfo.view.calendar;

      calendarApi.unselect(); // clear date selection

      const details = {
        title: this.state.title,
        start: start.toDate(),
        end: end.toDate(),
        allDay: this.state.allDay,
        description: this.state.description,
        type: this.state.type,
        student: this.state.student,
        tutor: this.state.tutor,
        frequency: this.state.frequency,
        participants: this.state.participants,
        color: {
          [this.props.user.localId]: this.state.color,
        },
        confirmed: "yes",
        creator: { ...this.props.user },
        id: generateId(
          this.state.type,
          start,
          this.state.frequency.index !== 0
        ),
        attachments: this.state.attachments,
      };

      if (this.state.type === "class") {
        details.color = {
          ...details.color,
          [details.tutor.localId]: colors.classDefault,
          [details.student.localId]: colors.classDefault,
        };
      }

      if (this.state.type === "event") {
        details.participants.push({ ...this.props.user, creator: true });
        details.participants.forEach((participant) => {
          if (!participant.creator) {
            details.color = {
              ...details.color,
              [participant.localId]: colors.eventDefault,
            };
          }
        });
      }

      if (this.state.type === "unavailability") {
        details.color = {
          ...details.color,
          YH5FFv1A3NVl3JKgc2ImVMaDLxC2: colors.unavailability,
        };
      }

      this.props.onCreateNewEvents({
        ...details,
        frequency: { ...details.frequency },
        color: { ...details.color },
        participants: [...details.participants],
      });

      let selectedUsers = [...this.state.selectedUsers];
      if (this.state.type === "class") {
        if (!selectedUsers.some((user) => user === details.tutor.name)) {
          selectedUsers.push(details.tutor.name);
        }
      }

      this.setState({
        ...this.state,
        selectInfo: null,
        selectedDate: moment(),
        selectedTime: ["00:00", "00:00"],
        type: "class",
        student: { name: "Selecione o aluno" },
        tutor: { name: "Selecione o tutor" },
        participants: [],
        attachments: [],
        frequency: { description: "Não se repete", index: 0 },
        title: "",
        description: "",
        color: colors.classDefault,
        unavailabilities: details.type === "unavailability",
        moment: start,
        allDay: true,
        selectedUsers,
        visible: {
          entries: false,
          checkBox: false,
          frequency: false,
          colorPicker: false,
          exclusion: false,
          infos: false,
          edition: false,
          answer: false,
        },
        error: {
          type: false,
          title: false,
          time: false,
          date: false,
          description: false,
          student: false,
          tutor: false,
          frequency: false,
        },
      });

      //Se faltarem campos para serem preenchidos, retorna erros
    } else {
      this.setState({
        ...this.state,
        error: { ...error },
      });
    }
  };

  handleCancel = () => {
    this.setState({
      ...this.state,
      selectInfo: null,
      selectedDate: moment(),
      selectedTime: ["00:00", "00:00"],
      type: "class",
      student: { name: "Selecione o aluno" },
      tutor: { name: "Selecione o tutor" },
      participants: [],
      attachments: [],
      frequency: { description: "Não se repete", index: 0 },
      title: "",
      description: "",
      allTutorsSelected: false,
      exclusionType: "this",
      answerType: "this",
      answer: "yes",
      sendNotification: "yes",
      allDay: true,
      error: {
        type: false,
        title: false,
        startTime: false,
        endTime: false,
        date: false,
        description: false,
        student: false,
        tutor: false,
        frequency: false,
      },
      visible: {
        entries: false,
        checkBox: false,
        frequency: false,
        colorPicker: false,
        exclusion: false,
        infos: false,
        answer: false,
      },
    });
  };

  handleChange = (e, option) => {
    let state = { ...this.state };
    let auxArray = [];
    let type = "";

    switch (option) {
      case "title":
        state.title = e.target.value;
        this.setState({ ...state });
        break;
      case "description":
        state.description = e.target.value;
        this.setState({ ...state });
        break;
      case "date":
        state.selectedDate = e;
        this.setState({ ...state });
        break;
      case "start":
        state.selectedTime[0] = e;
        this.setState({ ...state });
        break;
      case "end":
        state.selectedTime[1] = e;
        if (
          moment(state.selectedTime[1], "HH:mm").isSameOrBefore(
            moment(state.selectedTime[0], "HH:mm")
          )
        ) {
          state.selectedTime[1] = state.selectedTime[0];
          state.selectedTime[0] = e;
        }
        this.setState({ ...state });
        break;
      case "type":
        state.type = e.target.value;
        state.color =
          state.type === "class" ? colors.classDefault : colors.unavailability;
        state.allDay = state.type === "class" ? false : state.allDay;
        this.setState({
          ...state,
          error: {
            type: false,
            title: false,
            time: false,
            date: false,
            description: false,
            student: false,
            tutor: false,
            frequency: false,
          },
        });
        break;
      case "student":
        auxArray = this.props.students.filter((student) => student.name === e);
        state.student = auxArray[0];
        this.setState({ ...state });
        break;
      case "tutor":
        auxArray = this.props.tutors.filter((tutor) => tutor.name === e);
        state.tutor = { ...auxArray[0], answered: false, accepted: false };
        state.color = auxArray[0].color;
        this.setState({ ...state });
        break;
      case "participants":
        auxArray = this.props.allUsers.filter((user) => user.name === e);
        if (auxArray.length > 0) {
          state.participants.push({
            ...auxArray[0],
            answered: false,
            accepted: false,
          });
        }
        this.setState({ ...state });
        break;
      case "removeParticipant":
        state.participants = state.participants.filter(
          (user) => user.localId !== e.localId
        );
        this.setState({ ...state });
        break;
      case "frequency":
        auxArray = generateFrequencies(state.selectedDate);
        if (e === 4) {
          state.visible.frequency = true;
          if (state.frequency.index !== 4) {
            state.frequency = {
              ...customFrequency,
              date: state.selectedDate,
              endOn: state.selectedDate,
              index: e,
              onDay: [],
            };
          }
        } else {
          state.frequency = auxArray[e];
        }
        this.setState({ ...state });
        break;
      case "allDay":
        state.allDay = !state.allDay;
        this.setState({ ...state });
        break;
      case "sendNotification":
        state.sendNotification = e.target.value;
        this.setState({ ...state });
        break;
      case "exclusionType":
        state.exclusionType = e.target.value;
        this.setState({ ...state });
        break;
      case "answerType":
        state.answerType = e.target.value;
        this.setState({ ...state });
        break;
      case "includeSchedule":
        state.selectedUsers.push(e);
        auxArray = this.props.tutors.some((tutor) => tutor.name === e)
          ? this.props.tutors.filter((tutor) => tutor.name === e)
          : this.props.students.some((student) => student.name === e)
          ? this.props.students.filter((student) => student.name === e)
          : [];
        type = this.props.tutors.some((tutor) => tutor.name === e)
          ? "tutor"
          : "student";
        if (auxArray.length > 0) {
          this.props.onFetchUserClasses(type, auxArray[0].localId);
        }
        this.setState({ ...state });
        break;
      case "removeSchedule":
        state.selectedUsers = state.selectedUsers.filter((user) => user !== e);
        if (this.props.tutors.some((tutor) => tutor.name === e)) {
          state.allTutorsSelected = false;
        }
        if (e === "Aulas") {
          state.myClasses = !state.myClasses;
        }
        if (e === "Eventos") {
          state.myEvents = !state.myEvents;
        }
        this.setState({ ...state });
        break;
      case "clearSchedules":
        state.selectedUsers = ["Aulas", "Eventos"];
        state.allTutorsSelected = false;
        this.setState({ ...state });
        break;
      case "selectAll":
        if (state.allTutorsSelected) {
          auxArray = state.selectedUsers.filter(
            (user) => !e.some((item) => item.name === user)
          );
          state.selectedUsers = [...auxArray];
        } else {
          e.forEach((user) => {
            if (!state.selectedUsers.includes(user.name)) {
              state.selectedUsers.push(user.name);
            }
          });
          this.props.onFetchAllClasses();
        }
        state.allTutorsSelected = !state.allTutorsSelected;
        this.setState({ ...state });
        break;
      case "selectMyClasses":
        if (state.myClasses) {
          auxArray = state.selectedUsers.filter((user) => "Aulas" !== user);
          state.selectedUsers = [...auxArray];
        } else {
          if (!state.selectedUsers.includes("Aulas")) {
            state.selectedUsers.push("Aulas");
          }
        }
        state.myClasses = !state.myClasses;
        this.setState({ ...state });
        break;
      case "selectMyEvents":
        if (state.myEvents) {
          auxArray = state.selectedUsers.filter((user) => "Eventos" !== user);
          state.selectedUsers = [...auxArray];
        } else {
          if (!state.selectedUsers.includes("Eventos")) {
            state.selectedUsers.push("Eventos");
          }
        }
        state.myEvents = !state.myEvents;
        this.setState({ ...state });
        break;
      case "selectUnavailability":
        state.unavailabilities = !state.unavailabilities;
        if (state.unavailabilities) {
          if (!state.selectedUsers.includes("Indisponibilidades")) {
            state.selectedUsers.push("Indisponibilidades");
          }
        } else {
          auxArray = state.selectedUsers.filter(
            (user) => "Indisponibilidades" !== user
          );
          state.selectedUsers = [...auxArray];
        }
        this.setState({ ...state });
        break;
      case "selectHolidays":
        if (state.holidays) {
          auxArray = state.selectedUsers.filter((user) => "Feriados" !== user);
          state.selectedUsers = [...auxArray];
        } else {
          if (!state.selectedUsers.includes("Feriados")) {
            state.selectedUsers.push("Feriados");
          }
          //this.props.onFetchAllClasses();
        }
        state.holidays = !state.holidays;
        this.setState({ ...state });
        break;
      case "color":
        state.color = e;
        this.setState({ ...state });
        break;
      case "attachments":
        state.attachments = e;
        this.setState({ ...state });
      default:
        this.setState({ ...state });
        break;
    }
  };

  handleDateSelect = (selectInfo) => {
    if (selectInfo.view.type === "dayGridMonth") {
      this.setState({
        ...this.state,
        selectedDate: moment(selectInfo.start),
        visible: { ...this.state.visible, entries: true },
        selectInfo,
      });
    } else {
      let calendarApi = selectInfo.view.calendar;

      calendarApi.unselect(); // clear date selection

      if (
        moment(selectInfo.startStr).date() !== moment(selectInfo.endStr).date()
      ) {
        alert("Não é possível criar eventos entre dias");
      } else {
        this.setState({
          ...this.state,
          selectedDate: moment(selectInfo.start),
          selectedTime: [
            moment(selectInfo.startStr).format("HH:mm"),
            moment(selectInfo.endStr).format("HH:mm"),
          ],
          visible: { ...this.state.visible, entries: true },
          selectInfo,
          allDay: selectInfo.allDay,
        });
      }
    }
  };

  handleEventClick = (clickInfo) => {
    const id = clickInfo.event._def.publicId;
    const event = this.props.allEvents.filter(
      (event) => event.mainInfo.id === id
    );
    this.setState({
      ...this.state,
      selectedEvent: { ...event[0].details },
      visible: { ...this.state.visible, infos: true },
    });
  };

  handleDragAndDrop = (info) => {
    const changedEvent = info.event;
    const id = changedEvent._def.publicId;

    const aux = this.props.allEvents.filter(
      (event) => event.mainInfo.id === id
    );
    let newEvent = { ...aux[0] };

    let log = newEvent.details.log
      ? newEvent.details.log
      : `${moment().format("DD/MM/YYYY")}: [${moment(
          newEvent.details.start
        ).format("DD/MM/YYYY")}] [${moment(newEvent.details.start).format(
          "HH:mm"
        )} às ${moment(newEvent.details.end).format("HH:mm")}] [${getStatus(
          newEvent.details.confirmed
        )}]`;

    newEvent.mainInfo.start = moment(changedEvent._instance.range.start)
      .add(3, "h")
      .toDate();
    newEvent.mainInfo.end = moment(changedEvent._instance.range.end)
      .add(3, "h")
      .toDate();
    newEvent.details.start = newEvent.mainInfo.start;
    newEvent.details.end = newEvent.mainInfo.end;

    log =
      log +
      "\n" +
      `${moment().format("DD/MM/YYYY")}: [${moment(
        newEvent.details.start
      ).format("DD/MM/YYYY")}] [${moment(newEvent.details.start).format(
        "HH:mm"
      )} às ${moment(newEvent.details.end).format("HH:mm")}] [${getStatus(
        newEvent.details
      )}]`;

    let edited = { ...newEvent.details, log };

    this.props.onUpdateEvents(edited, true, null, false);
  };

  handleStatus = (details, status) => {
    const sendNotification =
      (details.confirmed === "yes" && status !== "yes") ||
      (details.confirmed !== "yes" && status === "yes");

    let log = details.log
      ? details.log
      : `${moment().format("DD/MM/YYYY")}: [${moment(details.start).format(
          "DD/MM/YYYY"
        )}] [${moment(details.start).format("HH:mm")} às ${moment(
          details.end
        ).format("HH:mm")}] [${getStatus(details.confirmed)}]`;

    log =
      log +
      "\n" +
      `${moment().format("DD/MM/YYYY")}: [${moment(details.start).format(
        "DD/MM/YYYY"
      )}] [${moment(details.start).format("HH:mm")} às ${moment(
        details.end
      ).format("HH:mm")}] [${getStatus(status)}]`;

    let edited = { ...details, log, confirmed: status };

    this.setState({
      ...this.state,
      visible: { ...this.state.visible, infos: false },
    });

    this.props.onUpdateClass(edited, sendNotification);
  };

  handleInvites = (details, option) => {
    const split = details.id.split("-");
    const isSingle = parseInt(split[2]) === 1;
    if (isSingle) {
      if (details.type === "event") {
        details.participants.forEach((participant) => {
          if (participant.localId === this.props.user.localId) {
            participant.answered = true;
            participant.accepted = option;
          }
        });
      } else {
        details.tutor.answered = true;
        details.tutor.accepted = option;
      }
      this.answerInvite(details, option, true);
      this.setState({
        ...this.state,
        visible: { ...this.state.visible, infos: false },
        answer: option,
      });
    } else {
      this.setState({
        ...this.state,
        visible: { ...this.state.visible, answer: true },
        answer: option,
      });
    }
  };

  answerInvite = (details, option, isSingle) => {
    if (isSingle) {
      this.props.onAnswerInvite(details, option, true);
    } else {
      if (details.type === "event") {
        details.participants.forEach((participant) => {
          if (participant.localId === this.props.user.localId) {
            participant.answered = true;
            participant.accepted = option;
          }
        });
      } else {
        details.tutor.answered = true;
        details.tutor.accepted = option;
      }
      this.setState({
        ...this.state,
        visible: { ...this.state.visible, answer: false },
        answer: option,
      });
      this.props.onAnswerInvite(
        details,
        option,
        this.state.answerType === "this"
      );
    }
  };

  eventsOfSelectedUsers = () => {
    let visibleEvents = [];
    this.props.allEvents.forEach((event) => {
      if (event.details.type === "unavailability") {
        if (this.state.unavailabilities) {
          const color = selectColor(
            event.details,
            this.props.tutors,
            this.props.user.localId
          );
          visibleEvents.push({
            ...event.mainInfo,
            display: "block",
            ...color,
          });
        }
      } else if (
        (event.details.type === "event" &&
          this.state.selectedUsers.includes("Eventos")) ||
        (event.details.type === "class" &&
          (this.state.selectedUsers.includes("Aulas") ||
            this.state.selectedUsers.includes(event.details.tutor.name) ||
            this.state.selectedUsers.includes(event.details.student.name)))
      ) {
        if (event.details.type === "class") {
          const color = selectColor(
            event.details,
            this.props.tutors,
            this.props.user.localId
          );
          visibleEvents.push({
            ...event.mainInfo,
            display: "block",
            title: setTitleClass(event.details),
            ...color,
          });
        } else {
          const color = selectColor(
            event.details,
            this.props.tutors,
            this.props.user.localId
          );
          visibleEvents.push({
            ...event.mainInfo,
            display: "block",
            ...color,
          });
        }
      }
    });
    return visibleEvents;
  };

  saveFrequency = (frequency) => {
    let repetionType = "";
    let onDay = null;
    let description = "";
    let date = this.state.selectedDate;
    //Organização dos item do objeto para ser enviado
    switch (frequency.repetionType) {
      case "diariamente":
        repetionType = "day";
        onDay = frequency.onDay;
        description = "Todos os dias";
        break;
      case "semanalmente":
        repetionType = "week";
        onDay = frequency.onDay;
        description = "Semanal: cada " + generateDaysOfWeek(frequency.onDay);
        if (!onDay.includes(moment(date).day())) {
          while (!onDay.includes(moment(date).day())) {
            date = moment(date).add(1, "day").toDate();
          }
        }
        break;
      //Para os casos mensal e anual, onDay é um dia do mês ou ano, não da semana.
      case "mensalmente":
        repetionType = "month";
        onDay = frequency.date;
        description =
          "Mensal: todo dia " +
          generateMessage(frequency.date, frequency.repetionType);
        break;
      case "anualmente":
        repetionType = "year";
        onDay = frequency.date;
        description =
          "Anual: todo dia " +
          generateMessage(frequency.date, frequency.repetionType);
        break;
      default:
        break;
    }
    this.setState({
      ...this.state,
      selectedDate: moment(date),
      visible: { ...this.state.visible, frequency: false },
      frequency: {
        ...frequency,
        repetionType,
        endType: frequency.endType,
        description,
        onDay,
      },
    });
  };

  handleExclusion = () => {
    this.props.onDeleteEvents(
      this.state.selectedEvent,
      this.state.exclusionType === "this",
      this.state.sendNotification === "yes"
    );
    this.setState({
      ...this.state,
      visible: { ...this.state.visible, exclusion: false, infos: false },
    });
  };

  closeModal = (modal) => {
    let state = { ...this.state };
    state.visible[modal] = false;
    this.setState({ ...state });
  };

  loadingFiles = (status) => {
    this.setState({ ...this.state, loadingFiles: status });
  };

  handleEdition = (edited, editionType, sendNotification) => {
    let deletedParticipants = [];
    const day = moment(edited.selectedDate).format("DD-MM-YYYY");
    const start = moment(
      `${day} ${edited.selectedTime[0]}`,
      "DD-MM-YYYY HH:mm"
    );
    const end = moment(`${day} ${edited.selectedTime[1]}`, "DD-MM-YYYY HH:mm");

    let log = edited.log
      ? edited.log
      : `${moment().format("DD/MM/YYYY")}: [${moment(
          this.state.selectedEvent.start
        ).format("DD/MM/YYYY")}] [${moment(
          this.state.selectedEvent.start
        ).format("HH:mm")} às ${moment(this.state.selectedEvent.end).format(
          "HH:mm"
        )}] [${getStatus(this.state.selectedEvent.confirmed)}]`;

    if (edited.type === "class") {
      if (
        !moment(start).isSame(moment(this.state.selectedEvent.start)) ||
        !moment(end).isSame(moment(this.state.selectedEvent.end))
      ) {
        log =
          log +
          "\n" +
          `${moment().format("DD/MM/YYYY")}: [${moment(start).format(
            "DD/MM/YYYY"
          )}] [${moment(start).format("HH:mm")} às ${moment(end).format(
            "HH:mm"
          )}] [${getStatus("yes")}]`;
      }
    }

    let details = {
      title: edited.title,
      start: start.toDate(),
      end: end.toDate(),
      allDay: edited.allDay,
      description: edited.description,
      type: edited.type,
      student: { ...edited.student },
      tutor: { ...edited.tutor },
      frequency: { ...edited.frequency },
      participants: [...edited.participants],
      color: { ...edited.color },
      confirmed: "yes",
      creator: { ...this.props.user },
      id: edited.id,
      attachments: [...edited.attachments],
      log: log,
    };

    let selectedUsers = [...this.state.selectedUsers];

    if (_.isEqual(details.frequency, this.state.selectedEvent.frequency)) {
      if (edited.type === "class") {
        if (edited.tutor.localId !== this.state.selectedEvent.tutor.localId) {
          this.props.onChangeTutor(
            details,
            this.state.selectedEvent.tutor,
            editionType === "this"
          );
          if (!selectedUsers.some((user) => user === edited.tutor.name)) {
            selectedUsers.push(edited.tutor.name);
          }
        } else {
          if (
            sendNotification === "yes" ||
            !moment(details.start).isSame(
              moment(this.state.selectedEvent.start)
            ) ||
            !moment(details.end).isSame(moment(this.state.selectedEvent.end))
          ) {
            this.props.onUpdateEvents(
              details,
              editionType === "this",
              null,
              true
            );
          } else {
            this.props.onUpdateEvents(details, editionType === "this");
          }
        }
      } else if (edited.type === "event") {
        if (
          equals(edited.participants, this.state.selectedEvent.participants)
        ) {
          if (
            sendNotification === "yes" ||
            !moment(details.start).isSame(
              moment(this.state.selectedEvent.start)
            ) ||
            !moment(details.end).isSame(moment(this.state.selectedEvent.end))
          ) {
            this.props.onUpdateEvents(
              details,
              editionType === "this",
              null,
              true
            );
          } else {
            this.props.onUpdateEvents(details, editionType === "this");
          }
        } else {
          this.state.selectedEvent.participants.forEach((participant) => {
            if (
              !edited.participants.some(
                (person) => person.localId === participant.localId
              )
            ) {
              deletedParticipants.push({ ...participant });
            }
            this.props.onUpdateEvents(details, editionType === "this");
          });
        }
      } else {
        this.props.onUpdateEvents(
          details,
          editionType === "this",
          deletedParticipants,
          true
        );
      }
    } else {
      details.id = generateId(
        details.type,
        details.start,
        details.frequency.index !== 0
      );
      this.props.onChangeOfPace(
        details,
        this.state.selectedEvent,
        sendNotification === "yes"
      );
    }

    this.setState({
      ...this.state,
      selectedEvent: {},
      selectedUsers,
      selectInfo: null,
      selectedDate: moment(),
      selectedTime: ["00:00", "00:00"],
      type: "class",
      student: { name: "Selecione o aluno" },
      tutor: { name: "Selecione o tutor" },
      participants: [],
      frequency: { description: "Não se repete", index: 0 },
      title: "",
      description: "",
      color: colors.classDefault,
      moment: moment(),
      allDay: true,
      visible: {
        entries: false,
        checkBox: false,
        frequency: false,
        colorPicker: false,
        exclusion: false,
        infos: false,
        edition: false,
      },
    });
  };

  render() {
    const events = this.eventsOfSelectedUsers();
    return (
      <div className="page reading">
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <h1 className="title">Agenda</h1>
          <FullCalendar
            nowIndicator
            selectable
            //editable
            dayMaxEventRows
            initialView="dayGridMonth"
            allDayContent="Dia todo"
            slotDuration="00:15:00"
            slotLabelInterval="00:15:00"
            locale="pt-br"
            //eventDrop={this.handleDragAndDrop}
            customButtons={{
              seeMore: {
                text: "Agendas",
                click: () =>
                  this.setState({
                    ...this.state,
                    visible: { ...this.state.visible, checkBox: true },
                  }),
              },
            }}
            headerToolbar={{
              left: "prev,next today seeMore",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            buttonText={{
              today: "hoje",
              month: "mês",
              week: "semana",
              day: "dia",
              list: "lista",
            }}
            slotLabelFormat={{
              hour: "2-digit",
              minute: "2-digit",
              omitZeroMinute: false,
              meridiem: "short",
            }}
            moreLinkText="eventos"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            eventChange={this.handleEvents}
            select={this.handleDateSelect}
            eventClick={this.handleEventClick}
            events={events}
          />
          {/*-------------------------------- MODAL - ENTRADAS -------------------------------------------------- */}
          <Modal
            title={
              this.state.type === "class"
                ? "Nova aula"
                : this.state.type === "event"
                ? "Novo evento"
                : "Nova indisponibilidade"
            }
            okButtonProps={{ loading: this.state.loadingFiles }}
            visible={this.state.visible.entries}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            okText="Salvar"
            cancelText="Cancelar"
            centered
            maskClosable
          >
            {/*-------------------------------- OPÇÕES -------------------------------------------------- */}
            <Form {...formItemLayout}>
              <Form.Item>
                <div className="row align-items-baseline">
                  <Radio.Group
                    buttonStyle="solid"
                    name="radiogroup"
                    value={this.state.type}
                    onChange={(e) => this.handleChange(e, "type")}
                    className={"events col-9 ml-3 "}
                  >
                    <div className="row mx-0">
                      {/* <div className="col-2 mr-3">
                        <Radio value="class">Aula</Radio>
                      </div> */}
                      <div className="col-2 mr-3">
                        <Radio value="event">Evento</Radio>
                      </div>
                      <div className="col-3 mx-2">
                        <Radio value="unavailability">Indisponibilidade</Radio>
                      </div>
                    </div>
                  </Radio.Group>
                  <button
                    className="col-1 button-color-picker mx-0"
                    style={{ backgroundColor: this.state.color }}
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        visible: { ...this.state.visible, colorPicker: true },
                      })
                    }
                  />
                </div>
              </Form.Item>
              {/*-------------------------------- TÍTULO -------------------------------------------------- */}
              {this.state.type === "event" && (
                <ItemFormulario
                  type="input"
                  error={!this.state.error.title ? "" : "error"}
                  placeholder="Título do evento"
                  handleChange={(e) => this.handleChange(e, "title")}
                  value={this.state.title}
                />
              )}
              {/*-------------------------------- ALUNO -------------------------------------------------- */}
              {this.state.type === "class" && (
                <ItemFormulario
                  type="person"
                  list={this.props.students}
                  error={!this.state.error.student ? "" : "error"}
                  placeholder="Selecione o aluno"
                  handleChange={(value) => this.handleChange(value, "student")}
                  value={this.state.student}
                />
              )}
              {/*-------------------------------- TUTOR -------------------------------------------------- */}
              {this.state.type === "class" && (
                <ItemFormulario
                  type="person"
                  list={this.props.tutors}
                  error={!this.state.error.tutor ? "" : "error"}
                  placeholder="Selecione o tutor"
                  handleChange={(value) => this.handleChange(value, "tutor")}
                  value={this.state.tutor}
                />
              )}
              {/*-------------------------------- DATA -------------------------------------------------- */}
              <ItemFormulario
                type="date"
                error={!this.state.error.date ? "" : "error"}
                placeholder="Selecione uma data"
                handleChange={(date, dateString) =>
                  this.handleChange(date, "date")
                }
                value={this.state.selectedDate}
              />
              {/*-------------------------------- HORÁRIO------------------------------------------------ */}
              <ItemFormulario
                type="time"
                error={!this.state.error.time ? "" : "error"}
                value={[this.state.selectedTime[0], this.state.selectedTime[1]]}
                handleChange={[
                  (time, timesString) =>
                    this.handleChange(timesString, "start"),
                  (time, timesString) => this.handleChange(timesString, "end"),
                ]}
                checkBox={() => this.handleChange(null, "allDay")}
                allDay={this.state.allDay}
                disabled={this.state.type === "class"}
              />
              {/*-------------------------------- FREQUÊNCIA ------------------------------------------------ */}
              <ItemFormulario
                type="frequency"
                list={generateFrequencies(this.state.selectedDate)}
                error={!this.state.error.frequency ? "" : "error"}
                value={this.state.frequency}
                handleChange={(value) => this.handleChange(value, "frequency")}
                placeholder="Selecione a frequência"
              />
              {/*-------------------------------- PARTICIPANTES -------------------------------------------------- */}
              {this.state.type === "event" && (
                <React.Fragment>
                  <ItemFormulario
                    type="person"
                    list={
                      this.props.user.type === "orientador"
                        ? this.props.allUsers
                        : this.props.tutors
                    }
                    error={!this.state.error.participants ? "" : "error"}
                    placeholder="Adicione participantes"
                    handleChange={(value) =>
                      this.handleChange(value, "participants")
                    }
                    value={{ name: "Adicione participantes" }}
                  />
                  <div className="participants-tags">
                    {this.state.participants.length > 0 &&
                      this.state.participants.map((item) => {
                        return (
                          <Tag
                            closable
                            key={`tag-participant-${item.localId}`}
                            onClose={() =>
                              this.handleChange(item, "removeParticipant")
                            }
                          >
                            {item.name} ({item.type.toUpperCase()})
                          </Tag>
                        );
                      })}
                  </div>
                </React.Fragment>
              )}
              {/*-------------------------------- DESCRIÇÃO------------------------------------------------ */}
              <ItemFormulario
                type="input"
                placeholder="Descrição"
                handleChange={(e) => this.handleChange(e, "description")}
                value={this.state.description}
              />
            </Form>
            {/*-------------------------------- ANEXOS -------------------------------------------------- */}
            {this.state.type !== "unavailability" && (
              <ItemFormulario
                type="file"
                disableOk={this.loadingFiles}
                handleChange={this.handleChange}
                attachmentsFile={this.state.attachments}
              />
            )}
          </Modal>
          {/*-------------------------------- MODAL - EXCLUSAO -------------------------------------------------- */}
          {this.state.visible.exclusion && (
            <ModalExclusao
              visible={this.state.visible.exclusion}
              details={this.state.selectedEvent}
              onCancel={() => this.closeModal("exclusion")}
              userInfos={this.props.user}
              handleChange={this.handleChange}
              exclusionType={this.state.exclusionType}
              sendNotification={this.state.sendNotification}
              onOk={this.handleExclusion}
            />
          )}
          {/*-------------------------------- MODAL - RESPOSTA CONVITES -------------------------------------------------- */}
          {this.state.visible.answer && (
            <ModalResposta
              visible={this.state.visible.answer}
              details={this.state.selectedEvent}
              onCancel={() => this.closeModal("answer")}
              userInfos={this.props.user}
              handleChange={this.handleChange}
              answerType={this.state.answerType}
              answer={this.state.answer}
              onOk={this.answerInvite}
            />
          )}
          {/*-------------------------------- MODAL - DETALHES -------------------------------------------------- */}
          {this.state.visible.infos && (
            <ModalInfo
              visible={this.state.visible.infos}
              details={this.state.selectedEvent}
              userInfos={this.props.user}
              handleStatus={this.handleStatus}
              onAnswer={this.handleInvites}
              closeModal={() => this.closeModal("infos")}
              onDelete={() =>
                this.setState({
                  ...this.state,
                  visible: { ...this.state.visible, exclusion: true },
                })
              }
              onEdit={() =>
                this.setState({
                  ...this.state,
                  visible: { ...this.state.visible, edition: true },
                })
              }
            />
          )}
          {/*-------------------------------- MODAL - EDICAO -------------------------------------------------- */}
          {this.state.visible.edition && (
            <Edicao
              visible={this.state.visible.edition}
              details={this.state.selectedEvent}
              allUsers={this.props.allUsers}
              isLoading={this.state.loadingFiles}
              students={this.props.students}
              tutors={this.props.tutors}
              handleCancel={() => this.closeModal("edition")}
              handleOk={this.handleEdition}
              userInfos={this.props.user}
            />
          )}
          {/*-------------------------------- MODAL - AGENDAS -------------------------------------------------- */}
          <SelecaoAgendas
            onOk={() => this.closeModal("checkBox")}
            onCancel={() => this.closeModal("checkBox")}
            visible={this.state.visible.checkBox}
            tutors={this.props.tutors}
            students={this.props.students}
            selected={this.state.selectedUsers}
            handleChange={this.handleChange}
            allSelected={this.state.allTutorsSelected}
            myClasses={this.state.myClasses}
            myEvents={this.state.myEvents}
            unavailabilities={this.state.unavailabilities}
            userInfos={this.props.user}
          />
          {/*-------------------------------- MODAL - COLOR PICKER -------------------------------------------------- */}
          <Modal
            title="Selecione uma nova cor"
            visible={this.state.visible.colorPicker}
            onOk={() => this.closeModal("colorPicker")}
            onCancel={() => this.closeModal("colorPicker")}
            okText="Selecionar"
            cancelText="Cancelar"
            centered
            maskClosable
          >
            <SliderPicker
              color={this.state.color}
              onChange={(e) => this.handleChange(e.hex, "color")}
            />
          </Modal>
          {/*-------------------------------- MODAL - FREQUÊNCIA -------------------------------------------------- */}
          {this.state.frequency.index === 4 && (
            <SelecaoFrequencias
              visible={this.state.visible.frequency}
              onOk={this.saveFrequency}
              onCancel={() =>
                this.setState({
                  ...this.state,
                  frequency: { description: "Não repete", index: 0 },
                })
              }
              currentFrequency={this.state.frequency}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ calendar, user, register }) => ({
  loading: calendar.loading,
  allEvents: calendar.current,
  tutors: register.tutors,
  students: register.students,
  user: {
    name: user.name,
    localId: user.localId,
    email: user.email,
    type: user.type,
    tokenNotification: user.tokenNotification,
  },
  allUsers: register.users,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateNewEvents: (details) => dispatch(uploadNewEvents(details)),
    onFetchUserEvents: () => dispatch(fetchUserEvents()),
    onFetchUserClasses: (type, id) => dispatch(fetchUserClasses(type, id)),
    onDeleteEvents: (details, isSingle, sendNotification) =>
      dispatch(deleteEvents(details, isSingle, sendNotification)),
    onFetchAllClasses: () => dispatch(fetchAllClasses()),
    onUpdateClass: (details, sendNotification) =>
      dispatch(updateClass(details, sendNotification)),
    onUpdateEvents: (
      details,
      isSingle,
      deletedParticipants,
      sendNotification
    ) =>
      dispatch(
        updateEvents(details, isSingle, deletedParticipants, sendNotification)
      ),
    onChangeTutor: (details, previousTutor, isSingle) =>
      dispatch(changeTutorInClass(details, previousTutor, isSingle)),
    onChangeOfPace: (current, previous, sendNotification) =>
      dispatch(changeOfPace(current, previous, sendNotification)),
    onAnswerInvite: (details, answer, isSingle) =>
      dispatch(answerInvite(details, answer, isSingle)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Principal);
