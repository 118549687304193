import React, { Component } from "react";
import { Redirect } from "react-router";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import { connect } from "react-redux";
import { newReport } from "../../../utils/store/actions/reportsActions";
import { info, createObject } from "../constantes";
import {
  Dropdown,
  Button,
  DatePicker,
  Checkbox,
  Input,
  Select,
  message,
  Menu as DropdownMenu,
} from "antd";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const { Option } = Select;

const initialReport = {
  id: "",
  student: "",
  tutor: "",
  date: "",
  timestamp: null,
  startOfClass: "",
  endOfClass: "",
  subject: [],
  content: "",
  workedInClass: [],
  behavior: [],
  perfomance: "",
  observation: "",
  given: "",
  orientation: "",
  verified: false,
  read: false,
  messages: [],
};

const initialState = {
  date: null,
  subjects: [],
  behaviors: [],
  workedInClass: [],
  report: { ...initialReport, subjects: [], behaviors: [], workedInClass: [] },
  loading: true,
  sending: false,
  redirect: false,
  error: {
    student: false,
    date: false,
    time: false,
    subject: false,
    content: false,
    workedInClass: false,
    behavior: false,
    perfomance: false,
    given: false,
    orientation: false,
  },
};

class Edicao extends Component {
  state = {
    ...initialState,
    error: { ...initialState.error },
    subjects: [],
    behaviors: [],
    workedInClass: [],
  };

  //Seta o relatório do estado da tela como o selecionado pelo usuário na página de Buscas
  componentDidMount() {
    const subjects = createObject(info.subjects);
    const behaviors = createObject(info.behaviors);
    const workedInClass = createObject(info.workedInClass);
    this.setState({
      ...this.state,
      report: {
        ...initialReport,
        subjects: [],
        behaviors: [],
        workedInClass: [],
      },
      subjects: subjects,
      behaviors: behaviors,
      workedInClass: workedInClass,
      loading: false,
    });
  }

  //Após o envio do relatório (mudança do estado) redireciona o usuário para a página de Buscas
  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      this.setState({
        ...initialState,
        report: {
          ...initialReport,
          subjects: [],
          behaviors: [],
          workedInClass: [],
        },
        redirect: true,
      });
      message.success("Relatório enviado com sucesso.");
    }
  };

  clear = () => {
    this.setState({
      ...initialState,
      report: {
        ...initialReport,
        subjects: [],
        behaviors: [],
        workedInClass: [],
      },
    });
  };

  getNames = (array) => {
    let names = [];
    array.forEach((element) => {
      if (element.isChecked) {
        names.push(element.name);
      }
    });
    return names;
  };

  handleDate = (date, stringDate) => {
    const state = { ...this.state };
    state.date = date;
    state.report.date = stringDate;
    this.setState({ ...state });
  };

  handleTime(e, option) {
    const state = { ...this.state };
    if (option === "start") {
      state.report.startOfClass = info.time[e.key];
      state.report.endOfClass = info.time[parseInt(e.key, 10) + 2];
    } else {
      state.report.endOfClass = info.time[e.key];
    }
    this.setState({ ...state });
  }

  //Funções para lidar com o toggle das checkboxes das matérias, comportamentos e quesitos trabalhados em aula
  toggleMateria = (e) => {
    const state = { ...this.state };
    state.subjects.forEach((subject) => {
      if (subject.name === e.target.id) {
        subject.isChecked = !subject.isChecked;
      }
    });
    this.setState({ ...state });
  };
  toggleBehavior = (e) => {
    const state = { ...this.state };
    state.behaviors.forEach((behavior) => {
      if (behavior.name === e.target.id) {
        behavior.isChecked = !behavior.isChecked;
      }
    });
    this.setState({ ...state });
  };
  toggleWorkedInClass = (e) => {
    const state = { ...this.state };
    state.workedInClass.forEach((item) => {
      if (item.name === e.target.id) {
        item.isChecked = !item.isChecked;
      }
    });
    this.setState({ ...state });
  };

  menuStartOfClass = (
    <DropdownMenu onClick={(e) => this.handleTime(e, "start")}>
      {info.time.map((time, index) =>
        time !== this.state.report.startOfClass ? (
          <DropdownMenu.Item key={`${index}`}>{time}</DropdownMenu.Item>
        ) : null
      )}
    </DropdownMenu>
  );

  menuEndOfClass = (
    <DropdownMenu onClick={(e) => this.handleTime(e, "end")}>
      {info.time.map((time, index) =>
        time !== this.state.report.endOfClass ? (
          <DropdownMenu.Item key={`${index}`}>{time}</DropdownMenu.Item>
        ) : null
      )}
    </DropdownMenu>
  );

  handleChange = (e, option) => {
    let state = { ...this.state };
    switch (option) {
      case "content":
        state.report.content = e.target.value;
        this.setState({ ...state });
        break;
      case "perfomance":
        state.report.perfomance = e.target.value;
        this.setState({ ...state });
        break;
      case "observation":
        state.report.observation = e.target.value;
        this.setState({ ...state });
        break;
      case "given":
        state.report.given = e.target.value;
        this.setState({ ...state });
        break;
      case "orientation":
        state.report.orientation = e.target.value;
        this.setState({ ...state });
        break;
      case "student":
        state.report.student = e;
        break;
      case "tutor":
        state.report.tutor = e;
        break;
      default:
        this.setState({ ...state });
        break;
    }
  };

  //Função para criar id para o relatório a partir do nome do aluno, data da aula e número aleatório de cinco dígitos.
  generateID = (date, name) => {
    const dateArray = date.split("/");
    const nameArray = name.split(" ");
    const id =
      nameArray[0] +
      nameArray[1] +
      dateArray[0] +
      dateArray[1] +
      dateArray[2] +
      parseInt(Math.random() * 100000);
    return id;
  };

  checkRequired = () => {
    let state = { ...this.state };
    let flag = false;
    const behaviors = this.getNames(state.behaviors);
    const subjects = this.getNames(state.subjects);
    const workedInClass = this.getNames(state.workedInClass);
    if (state.report.student.trim() === "") {
      state.error.student = true;
      flag = true;
    } else {
      state.error.student = false;
    }
    if (state.report.date.trim() === "") {
      state.error.date = true;
      flag = true;
    } else {
      state.error.date = false;
    }
    if (
      state.report.startOfClass.trim() === "" ||
      state.report.endOfClass.trim() === ""
    ) {
      state.error.time = true;
      flag = true;
    } else {
      state.error.time = false;
    }
    if (subjects.length === 0) {
      state.error.subject = true;
      flag = true;
    } else {
      state.error.subject = false;
    }
    if (state.report.content.trim() === "") {
      state.error.content = true;
      flag = true;
    } else {
      state.error.content = false;
    }
    if (workedInClass.length === 0) {
      state.error.workedInClass = true;
      flag = true;
    } else {
      state.error.workedInClass = false;
    }
    if (behaviors.length === 0) {
      state.error.behavior = true;
      flag = true;
    } else {
      state.error.behavior = false;
    }
    if (state.report.perfomance.trim() === "") {
      state.error.perfomance = true;
      flag = true;
    } else {
      state.error.perfomance = false;
    }
    if (state.report.given.trim() === "") {
      state.error.given = true;
      flag = true;
    } else {
      state.error.given = false;
    }
    if (state.report.orientation.trim() === "") {
      state.error.orientation = true;
      flag = true;
    } else {
      state.error.orientation = false;
    }

    if (flag) {
      message.error("Todos os campos obrigatórios precisam ser preenchidos.");
      this.setState({ ...state });
    } else {
      this.setState({ ...state });
      this.newReport();
    }
  };

  newReport = () => {
    this.setState({ sending: true });
    //O novo relatório, enviado para a store, é aquele que está no state da tela
    const report = { ...this.state.report };
    //Verifica quais materias, comportamentos e itens dados foram selecionados e envia somente o nome
    report.subject = this.getNames(this.state.subjects);
    report.behavior = this.getNames(this.state.behaviors);
    report.workedInClass = this.getNames(this.state.workedInClass);
    report.tutor = this.props.name;
    report.observation = this.state.report.observation
      ? this.state.report.observation
      : "Sem observação";
    report.verified = false;
    report.id = this.generateID(report.date, report.student);
    const string = report.date + " " + report.startOfClass;
    const date = moment(string, "DD/MM/YYYY hh:mm").toDate();
    report.timestamp = date.getTime();
    //Como a edição se completa nessa função, alterar o status de verified para true
    this.props.onNewReport(report);
  };

  render() {
    return this.state.redirect ? (
      <Redirect to="/relatorios" />
    ) : this.state.loading ? null : (
      <div className="page new-report">
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <h1 className="title">Enviar relatório de aula</h1>
          {/*--------------------------------- ALUNO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Aluno</h3>
            </div>
            <div
              className={`sectionContent ${
                this.state.error.student ? "error" : ""
              }`}
            >
              <Select
                size="large"
                placeholder="Selecione um aluno"
                showSearch
                optionFilterProp="children"
                onChange={(value) => this.handleChange(value, "student")}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.props.students.map((user) => (
                  <Option key={`${user.name}`} value={user.name}>
                    {user.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {/*--------------------------------- DATA ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Data da aula</h3>
            </div>
            <div
              className={`sectionContent ${
                this.state.error.date ? "error" : ""
              }`}
            >
              <DatePicker
                defaultValue={new Date()}
                value={this.state.date}
                format="DD/MM/YYYY"
                size="large"
                onChange={(date, dateString) =>
                  this.handleDate(date, dateString)
                }
              />
            </div>
          </div>
          {/*--------------------------------- HORÁRIO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Horário da aula</h3>
            </div>
            <div
              className={`sectionContent ${
                this.state.error.time ? "error" : ""
              }`}
            >
              <Dropdown overlay={this.menuStartOfClass} className="time">
                <Button size="default">
                  {this.state.report.startOfClass} <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown overlay={this.menuEndOfClass} className="time">
                <Button size="default">
                  {this.state.report.endOfClass} <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
          {/*--------------------------------- MATÉRIAS ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Matérias</h3>
            </div>
            <div
              className={`sectionContent ${
                this.state.error.subject ? "error" : ""
              }`}
            >
              {this.state.subjects.map((subject, index) => (
                <div className="checkbox" key={`subject${index}`}>
                  <Checkbox
                    checked={subject.isChecked}
                    id={subject.name}
                    onChange={(e) => this.toggleMateria(e)}
                  >
                    {subject.name}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
          {/*--------------------------------- CONTEÚDO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Conteúdo</h3>
            </div>
            <div
              className={`sectionContent ${
                this.state.error.content ? "error" : ""
              }`}
            >
              <TextArea
                value={this.state.report.content}
                onChange={(e) => this.handleChange(e, "content")}
              />
            </div>
          </div>
          {/*--------------------------------- O QUE FOI TRABALHADO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">O que foi trabalhado</h3>
            </div>
            <div
              className={`sectionContent ${
                this.state.error.workedInClass ? "error" : ""
              }`}
            >
              {this.state.workedInClass.map((workedInClass, index) => (
                <div className="checkbox" key={`workedInClass${index}`}>
                  <Checkbox
                    checked={workedInClass.isChecked}
                    id={workedInClass.name}
                    onChange={(e) => this.toggleWorkedInClass(e)}
                  >
                    {workedInClass.name}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
          {/*--------------------------------- COMPORTAMENTO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Comportamento</h3>
            </div>
            <div
              className={`sectionContent ${
                this.state.error.behavior ? "error" : ""
              }`}
            >
              {this.state.behaviors.map((behavior, index) => (
                <div className="checkbox" key={`behavior${index}`}>
                  <Checkbox
                    checked={behavior.isChecked}
                    id={behavior.name}
                    onChange={(e) => this.toggleBehavior(e)}
                  >
                    {behavior.name}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
          {/*--------------------------------- DESEMPENHO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Desempenho do aluno</h3>
            </div>
            <div
              className={`sectionContent larger-text-area ${
                this.state.error.perfomance ? "error" : ""
              }`}
            >
              <TextArea
                value={this.state.report.perfomance}
                onChange={(e) => this.handleChange(e, "perfomance")}
              />
            </div>
          </div>
          {/*--------------------------------- OBSERVAÇÃO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Observação</h3>
            </div>
            <div className={`sectionContent larger-text-area`}>
              <TextArea
                value={this.state.report.observation}
                onChange={(e) => this.handleChange(e, "observation")}
                placeholder="Preencha se houver alguma observação importante para os pais ou tarefa para casa."
              />
            </div>
          </div>
          {/*--------------------------------- DADO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">O que foi dado</h3>
            </div>
            <div
              className={`sectionContent ${
                this.state.error.given ? "error" : ""
              }`}
            >
              <TextArea
                value={this.state.report.given}
                onChange={(e) => this.handleChange(e, "given")}
              />
            </div>
          </div>
          {/*--------------------------------- ORIENTAÇÃO ----------------------------------------- */}
          <div className="section">
            <div className="sectionTitle">
              <h3 className="item-title">Orientação para a próxima aula</h3>
            </div>
            <div
              className={`sectionContent larger-text-area ${
                this.state.error.orientation ? "error" : ""
              }`}
            >
              <TextArea
                value={this.state.report.orientation}
                onChange={(e) => this.handleChange(e, "orientation")}
              />
            </div>
          </div>
          {/*--------------------------------- ENVIAR ----------------------------------------- */}
          <Button
            type="primary"
            className="submit"
            onClick={(e) => this.checkRequired()}
            loading={this.state.sending}
          >
            Enviar
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ reports, user, register }) => ({
  reports: reports.reports,
  loading: reports.isLoading,
  name: user.name,
  students: register.students.sort(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onNewReport: (report) => dispatch(newReport(report)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edicao);
