import {
  SET_USERS_FOR_REGISTER,
  SET_USER_INFO,
  FETCHING_INFO,
  INFO_LOADED,
  UPDATING_INFO,
  INFO_UPDATED,
  FILTER_USERS_BY_NAME,
  CLEAR_FILTER,
  SET_TYPE_USER,
  SET_STUDENTS,
  SET_TUTORS,
} from "../actions/actionTypes";
import _ from "lodash";

const initialState = {
  users: [],
  tutors: [],
  students: [],
  parents: [],
  selectedUser: {},
  selectedType: "",
  filteredUsers: [],
  loading: false,
  registering: false,
};

//Função para identificar o usuário a partir do filtro (nome passado na busca)
const filterByName = (users, filter, type) => {
  let filtered = [];
  users.forEach((user) => {
    if (filter !== "") {
      const name = _.lowerCase(user.name);
      const toCompare = _.lowerCase(filter);
      if (type.includes("aluno")) {
        if (name.includes(toCompare) && user.type.includes(type)) {
          filtered.push(user);
        }
      } else if (type === "responsavel") {
        if (name.includes(toCompare) && user.type === type) {
          filtered.push(user);
        }
      } else if (type === "tutor") {
        if (
          name.includes(toCompare) &&
          (user.type === "tutor" ||
            user.type === "orientador" ||
            user.type === "admin")
        ) {
          filtered.push(user);
        }
      }
    }
  });
  return filtered;
};

const filterByType = (users, type) => {
  const result = users.filter((user) => {
    if (type === "aluno") {
      return user.type.includes(type);
    } else if (type === "tutor") {
      return (
        user.type === "tutor" ||
        user.type === "orientador" ||
        user.type === "admin"
      );
    } else if (type === "responsavel") {
      return user.type === "responsavel";
    }
  });
  return result;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS_FOR_REGISTER:
      return {
        ...state,
        users: action.payload.users,
        tutors: action.payload.tutors,
        students: action.payload.students,
        loading: false,
      };
    case SET_USER_INFO:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case FETCHING_INFO:
      return {
        ...state,
        loading: true,
      };
    case INFO_LOADED:
      return {
        ...state,
        loading: false,
      };
    case UPDATING_INFO:
      return {
        ...state,
        registering: true,
      };
    case INFO_UPDATED: {
      return {
        ...state,
        registering: false,
      };
    }
    case SET_TUTORS: {
      return {
        ...state,
        tutors: action.payload,
      };
    }
    case SET_STUDENTS: {
      return {
        ...state,
        students: action.payload,
      };
    }
    case SET_TYPE_USER:
      return {
        ...state,
        selectedType: action.payload,
      };
    case FILTER_USERS_BY_NAME: {
      return {
        ...state,
        filteredUsers: filterByName(
          state.users,
          action.payload.filter,
          action.payload.type
        ),
      };
    }
    case CLEAR_FILTER:
      return {
        ...state,
        filteredUsers: [],
      };
    default:
      return state;
  }
};

export default reducer;
