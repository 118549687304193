export const dateFormatter = (moment) => {
  const day = moment.getDate();
  const month = moment.getMonth() + 1;
  const year = moment.getFullYear();

  let date = " ";

  if (month < 10) {
    if (day < 10) {
      date = "0" + day + "/0" + month + "/" + year;
    } else {
      date = day + "/0" + month + "/" + year;
    }
  } else {
    if (day < 10) {
      date = "0" + day + "/" + month + "/" + year;
    } else {
      date = day + "/" + month + "/" + year;
    }
  }
  return date;
};

export const timeFormatter = (moment) => {
  const hour = moment.getHours();
  const minutes = moment.getMinutes();
  let time = " ";
  if (hour < 10) {
    if (minutes < 10) {
      time = "0" + hour + ":0" + minutes;
    } else {
      time = "0" + hour + ":" + minutes;
    }
  } else {
    if (minutes < 10) {
      time = hour + ":0" + minutes;
    } else {
      time = hour + ":" + minutes;
    }
  }
  return time;
};

export const calendarTime = (time) => {
  const splits = time.split(":");
  const result = {
    hour: parseInt(splits[0]),
    minutes: parseInt(splits[1]),
  };
  return result;
};

export const calendarDate = (date) => {
  const splits = date.split("/");
  const result = {
    day: parseInt(splits[0]),
    month: parseInt(splits[1]) - 1,
    year: parseInt(splits[2]),
  };
  return result;
};


export const colors = {
    classDefault: "#0073aa",
    eventDefault: "#56ba45",
    orange: "#ff8c2e",
    unavailability: "#FF0000"
};