import {
  SET_REPORTS,
  SORT_REPORTS,
  FILTER_REPORT_BY_NAME,
  CLEAR_FILTER,
  FILTER_REPORT_BY_DATE,
  EDIT_REPORT,
  UPLOADING_REPORT,
  REPORT_SENT,
  SET_OLD_REPORTS,
  REPORT_LOGGED_OUT,
} from "../actions/actionTypes";
import moment from "moment";
import _ from "lodash";

const initialState = {
  reports: [],
  filteredReports: [],
  students: [],
  isLoading: false,
  timestamps: {
    older: null,
    newer: null,
  },
  oldReports: [],
};

const unverifiedReports = (reports) => {
  let unverified = [];
  reports.forEach((report) => {
    if (!report.verified) {
      unverified.push(report);
    }
  });
  const sorted = unverified.sort(
    (a, b) => moment(b.date, "DD-MM-YYYY") - moment(a.date, "DD-MM-YYYY")
  );
  return sorted;
};

const verifiedReports = (reports) => {
  let verified = [];
  reports.forEach((report) => {
    if (report.verified) {
      verified.push(report);
    }
  });
  const sorted = verified.sort(
    (a, b) => moment(b.date, "DD-MM-YYYY") - moment(a.date, "DD-MM-YYYY")
  );
  return sorted;
};

//Função para identificar os relatórios de alunos a partir do filtro (nome passado na busca)
export const filterByName = (reports, filter) => {
  let filtered = [];
  reports.forEach((report) => {
    const name = _.lowerCase(report.student);
    const toCompare = _.lowerCase(filter);
    if (name.includes(toCompare)) {
      filtered.push(report);
    }
  });
  return filtered;
};

const filterByDate = (reports, initialDate, finalDate) => {
  let filtered = [];
  const beggining = moment(initialDate, "DD-MM-YYYY").subtract(1, "d");
  const ending = moment(finalDate, "DD-MM-YYYY").add(1, "d");
  reports.forEach((report) => {
    if (moment(report.date, "DD-MM-YYYY").isBetween(beggining, ending)) {
      filtered.push(report);
    }
  });
  return filtered;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORTS:
      return {
        ...state,
        reports: action.payload.reports,
        timestamps: {
          older: action.payload.older,
          newer: action.payload.newer,
        },
        isLoading: false,
      };
    case SET_OLD_REPORTS:
      return {
        ...state,
        oldReports: action.payload,
        isLoading: false,
      };
    case REPORT_LOGGED_OUT:
      return {
        ...initialState,
      };
    case SORT_REPORTS:
      const sortedReports = unverifiedReports(state.reports).concat(
        verifiedReports(state.reports)
      );
      return {
        ...state,
        reports: sortedReports,
      };
    case FILTER_REPORT_BY_NAME:
      const namefiltered = filterByName(state.reports, action.payload);
      const filteredArrayName = unverifiedReports(namefiltered).concat(
        verifiedReports(namefiltered)
      );
      return {
        ...state,
        filteredReports: filteredArrayName,
      };
    case FILTER_REPORT_BY_DATE:
      let datefiltered = filterByDate(
        state.reports,
        action.payload.initialDate,
        action.payload.endDate
      );
      const filteredArray = unverifiedReports(datefiltered).concat(
        verifiedReports(datefiltered)
      );
      return {
        ...state,
        filteredReports: filteredArray,
      };
    // case EDIT_REPORT: {
    //   const index = findPosition(state.reports, action.payload.id);
    //   const newState = { ...state };
    //   newState.reports[index] = action.payload;
    //   newState.reports[index].verified = true;
    //   return {
    //     ...newState,
    //   };
    // }
    case UPLOADING_REPORT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case REPORT_SENT: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CLEAR_FILTER:
      return {
        ...state,
        filteredReports: [],
      };
    default:
      return state;
  }
};

export default reducer;
