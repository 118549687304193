import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Upload,
  message,
  Button,
  Checkbox,
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";
import ShortText from "@material-ui/icons/ShortText";
import {
  ClockCircleOutlined,
  CalendarOutlined,
  UserOutlined,
  FileAddOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { getBase64 } from "./helpers/functions";
import { functionsBaseURL } from "./helpers/constants";
import axios from "axios";

const { Option } = Select;

const orange = "#ff8c2e";
const fontSize = 16;

export default ({
  error,
  type,
  handleChange,
  value,
  placeholder,
  list,
  allDay,
  checkBox,
  disableOk,
  disabled,
  attachmentsFile,
  space
}) => {
  const [fileList, setFileList] = useState([]);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (type === "file" && attachmentsFile.length === 0) {
      setFileList([]);
      setAttachments([]);
    } else if (type === "file" && attachmentsFile.length !== 0){
      let list = []
      attachmentsFile.forEach((file, index) => {
        list.push({
          uid: `${index}`,
          name: `${file.name}`,
          status: "done",
          url: `${file.uri}`,
        })
      })
      console.log(list)
      setFileList([...list])
    }
  }, [attachmentsFile]);

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      const newAttachments = attachments.filter(
        (item) => item.name !== file.name
      );
      setAttachments(newAttachments);
      handleChange(newAttachments, "attachments");
      return {
        fileList: newFileList,
      };
    },
    beforeUpload: (file) => {
      console.log('called')
      setFileList([...fileList, file]);
      disableOk(true);
      getBase64(file).then((result) => {
        axios({
          url: "uploadDoc",
          baseURL: functionsBaseURL,
          method: "post",
          data: result,
        })
          //Se o upload tiver sido bem sucedido, atualiza o anexo com o link do bd
          .then((res) => {
            setAttachments([
              ...attachments,
              {
                name: file.name,
                uri: res.data.uploadUri,
                status: "success",
              },
            ]);
            handleChange(
              [
                ...attachments,
                {
                  name: file.name,
                  uri: res.data.uploadUri,
                  status: "success",
                },
              ],
              "attachments"
            );
            disableOk(false);
          })
          //Senão, mostra um ícone de erro ao usuário
          .catch((err) => {
            message.error(`Ocorreu um erro no upload do arquivo ${file.name}`);
            console.log(err);
            const newFileList = fileList.filter((item) => item !== file);
            setFileList(newFileList);
            disableOk(false);
          });
      });
      return false;
    },
    fileList,
  };

  return (
    <Form.Item validateStatus={error}>
      <div className="row align-items-center">
        {/* ---------------------------------- ÍCONES ----------------------------------------------- */}
        <div className="col-1 pr-0 mr-3">
          {type === "input" && <ShortText style={{ color: orange }} />}
          {type === "date" && (
            <CalendarOutlined style={{ color: orange, fontSize: fontSize }} />
          )}
          {type === "time" && (
            <ClockCircleOutlined
              style={{ color: orange, fontSize: fontSize }}
            />
          )}
          {type === "person" && (
            <UserOutlined style={{ color: orange, fontSize: fontSize }} />
          )}
          {type === "frequency" && (
            <ClockCircleOutlined
              style={{ color: orange, fontSize: fontSize }}
            />
          )}
          {type === "file" && (
            <FileAddOutlined style={{ color: orange, fontSize: fontSize }} />
          )}
        </div>
        {/* ------------------------------- CAMPOS DE ENTRADA ------------------------------------------*/}
        {/* ********** TEXTO ******** */}
        {type === "input" && (
          <div className={space ? "col-9 pl-2" : "col-9 pl-0"}>
            <Input
              allowClear
              placeholder={placeholder}
              onChange={handleChange}
              value={value}
              disabled={disabled}
            />
          </div>
        )}
        {/* ********** DATA ******** */}
        {type === "date" && (
          <div className="col-6 pl-0">
            <DatePicker
              placeholder={placeholder}
              style={{ width: "100%" }}
              value={value}
              onChange={handleChange}
              locale={locale}
              format="DD/MM/YYYY"
              suffixIcon=""
              allowClear={false}
              disabled={disabled}
            />
          </div>
        )}
        {/* ********** HORÁRIO ******** */}
        {type === "time" && (
          <React.Fragment>
            <div className="col-3 pl-0 time-picker">
              <TimePicker
                onChange={handleChange[0]}
                format="HH:mm"
                placeholder="Selecione o horário"
                value={moment(value[0], "HH:mm")}
                suffixIcon=""
                disabled={disabled ? true : allDay ? true : false}
                allowClear={false}
                showNow={false}
              />
            </div>
            <div className="col-3 pl-0 time-picker">
              <TimePicker
                onChange={handleChange[1]}
                format="HH:mm"
                placeholder="Selecione o horário"
                value={moment(value[1], "HH:mm")}
                suffixIcon=""
                disabled={disabled ? true : allDay ? true : false}
                allowClear={false}
                showNow={false}
              />
            </div>
            {!disabled && (
              <div className="col-3 pl-0">
                <Checkbox onChange={checkBox} checked={allDay}>
                  Dia todo
                </Checkbox>
              </div>
            )}
          </React.Fragment>
        )}
        {/* ********** USUÁRIO ******** */}
        {type === "person" && (
          <div className={space ? "col-9 pl-2" : "col-9 pl-0"}>
          <Select
              showSearch
              disabled={disabled}
              placeholder={placeholder}
              optionFilterProp="children"
              value={value.name}
              onChange={handleChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {list.map((user) => (
                <Option key={user.id} value={user.name}>
                  {user.name}
                </Option>
              ))}
            </Select>
          </div>
        )}
        {/* ********** SELEÇÃO DE FREQUÊNCIA ******** */}
        {type === "frequency" && (
          <div className="col-9 pl-0">
            <Select
              placeholder={placeholder}
              onChange={handleChange}
              value={value.description}
              disabled={disabled}
            >
              {list.map((options, index) => (
                <Option key={options.description} value={options.index}>
                  {options.description}
                </Option>
              ))}
            </Select>
          </div>
        )}
        {/* ********** ARQUIVOS ******** */}
        {type === "file" && (
          <div className="col-9 pl-0">
            <Upload {...props} >
              <Button icon={<UploadOutlined />}>Adicionar arquivo</Button>
            </Upload>
          </div>
        )}
      </div>
    </Form.Item>
  );
};
